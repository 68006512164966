import React, { useState } from 'react'
import styled from 'styled-components'
import logo from '../images/logos/logo-restaurante-borrasco-sombra.png'
import { media } from '../styles/styleUtils'
import { Link } from 'gatsby'
import LogoFacebook from '../images/social-media/facebook-icon.svg'
import LogoInstagram from '../images/social-media/instagram-icon.svg'

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  ${media.lg`
  width: 100%;
  `}
  ${media.md`
    width: 100%;
    justify-content: center;
    align-items: flex-start;
  `}
`
const Ul = styled.ul<{ open: boolean }>`
  list-style: none;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  li {
    padding: 10px 10px;
    a {
      font-size: 1.3rem;
    }
    display: flex;
    justify-content: center;
    align-items: center; /* <---- NEW    */
  }
  ${media.lg`
    li {
      a {
        font-size: 0.9rem;
      }
    }
  `}
  ${media.md`
    z-index: -1;
    flex-flow: column nowrap;
    background-color: #fff;
    position: fixed;
    transform: ${({ open }) => (open ? 'translateX(0)' : 'translateX(100%)')};
    top: 0;
    right: 0;
    height: 100vh;
    width: 300px;
    padding-top: 3.5rem;
    transition: transform 0.3s ease-in-out;
    li {
      color: #000;
    }
  `}
`

const LogoWrapper = styled.img`
  width: 200px;
`

interface RightNavProps {
  open: boolean
}

const RightNav: React.FC<RightNavProps> = ({ open }: RightNavProps) => {
  return (
    <Ul open={open}>
      <li>
        <Link to="/">
          <LogoWrapper
            alt="Logo del Restaurante Borrasco de Lerín"
            src={logo}
          ></LogoWrapper>
        </Link>
      </li>
      <li>
        <Link to="/quienes-somos">Quienes somos</Link>
      </li>
      <li>
        <Link to="/la-carta">La carta</Link>
      </li>
      <li>
        <Link to="/menus">Menús</Link>
      </li>
      <li>
        <Link to="/galeria">Galería</Link>
      </li>
      <li>
        <Link to="/bono-regalo">Bono regalo</Link>
      </li>
      <li>
      <Link to="/contacto">Contacto</Link>
      </li>
      <a
        href="https://es-es.facebook.com/restauranteborrasco"
        target="_blank"
        rel="noreferrer"
      >
        <img style={{ width: '25px' }} src={LogoFacebook} />
      </a>
      <a
        href="https://www.instagram.com/restauranteborrasco/"
        target="_blank"
        rel="noreferrer"
      >
        <img style={{ width: '42px' }} src={LogoInstagram} />
      </a>
    </Ul>
  )
}

const StyledBurger = styled.div<{ open: boolean }>`
  width: 2rem;
  height: 2rem;
  position: fixed;
  top: 15px;
  right: 30px;
  z-index: 250;
  display: none;
  @media (max-width: 768px) {
    display: flex;
    justify-content: space-around;
    flex-flow: column nowrap;
  }
  div {
    width: 2rem;
    height: 0.15rem;
    background-color: ${({ open }) => (open ? '#ccc' : '#333')};
    border-radius: 10px;
    transform-origin: 1px;
    transition: all 0.3s linear;
    &:nth-child(1) {
      transform: ${({ open }) => (open ? 'rotate(45deg)' : 'rotate(0)')};
    }
    &:nth-child(2) {
      transform: ${({ open }) => (open ? 'translateX(100%)' : 'translateX(0)')};
      opacity: ${({ open }) => (open ? 0 : 1)};
    }
    &:nth-child(3) {
      transform: ${({ open }) => (open ? 'rotate(-45deg)' : 'rotate(0)')};
    }
  }
`

const Burger = () => {
  const [open, setOpen] = useState(false)

  return (
    <>
      <StyledBurger open={open} onClick={() => setOpen(!open)}>
        <div />
        <div />
        <div />
        <RightNav open={open} />
      </StyledBurger>
    </>
  )
}

const LogoVistaResponsive = styled.img<{ open: boolean }>`
  width: 150px;
  display: none;
  ${media.md`
    display: flex;
  `}
`

export default () => {
  return (
    <Wrapper>
      <Link to="/">
        <LogoVistaResponsive
          alt="Logo Restaurante Borrasco de Lerín"
          open={true}
          src={logo}
        ></LogoVistaResponsive>
      </Link>

      <Burger />
      <Ul open={false}>
        <li>
          <Link to="/">
            <LogoWrapper
              alt="Logo del Restaurante Borrasco"
              src={logo}
            ></LogoWrapper>
          </Link>
        </li>
        <li>
          <Link to="/quienes-somos">Quienes somos</Link>
        </li>
        <li>
          <Link to="/la-carta">La carta</Link>
        </li>
        <li>
          <Link to="/menus">Menús</Link>
        </li>
        <li>
          <Link to="/galeria">Galería</Link>
        </li>
        <li>
          <Link to="/bono-regalo">Bono regalo</Link>
        </li>
        <li>
        <Link to="/contacto">Contacto</Link>
        </li>
        <li>
          <a
            href="https://es-es.facebook.com/restauranteborrasco"
            target="_blank"
            rel="noreferrer"
          >
            <img style={{ width: '25px' }} src={LogoFacebook} />
          </a>
          <a
            href="https://www.instagram.com/restauranteborrasco/"
            target="_blank"
            rel="noreferrer"
          >
            <img style={{ width: '42px' }} src={LogoInstagram} />
          </a>
        </li>
      </Ul>
    </Wrapper>
  )
}
